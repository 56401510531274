import React from 'react';
import Dialog from './components/alert/Dialog';
import { cpf as validaCPF } from 'cpf-cnpj-validator'; 
import WS from './config/Ws';
import { emailIsValid } from './utils/validations/email';
import { validaPhone } from './utils/validations/phone';
import Requisitions from './utils/requisitions/Requisitions';
import Dialog2 from './components/alert/Dialog2';

function submitHandler(args){
    const {
        plano,
        nome,
        cpf_cnpj,
        telefone,
        email,
        senha,
        confirmacao_senha,
        tipo,
        gCaptcha
      } = args;

    //  validação de dados do formulário básico
    if(!plano){
        callAlert('Escolha um plano!');
        return false;
    }
    if(!nome){
        callAlert('Preencha o seu nome!');
        return false;
    }
    if(!cpf_cnpj){
        callAlert('Preencha o seu CPF!');
        return false;
    }
    if(!telefone){
        callAlert('Preencha o seu telefone!');
        return false;
    }
    if(!email){
        callAlert('Preencha o seu email!');
        return false;
    }
    if(!emailIsValid(email)){
        callAlert('Digite um email válido!');
        return;
      }
    if(!senha){
        callAlert('Escolha uma senha!');
        return false;
    }
    if(!confirmacao_senha){
        callAlert('Por favor, confirme sua senha!');
        return false;
    }
    if(confirmacao_senha !== senha){
        callAlert('A senha e a confirmação de senha não conferem!');
        return false;
    }
    if(!emailIsValid(email)) {
        callAlert('Digite um email válido');
        return false;
    }

    if(!validaCPF.isValid(cpf_cnpj)) {
        callAlert('Digite um cpf válido');
        return false;
    }

    if(!validaPhone(telefone)) {
        callAlert('Digite um telefone válido');
        return false;
    }

    if(!gCaptcha) {
        callAlert('Confirme que você não é um robô!');
        return false;
    }

    if(tipo === 'cartao'){
        buyByCard(args);
    } else {
        buyByBoleto(args);
    }
}

function buyByCard(args) {
    const {
        ano,
        bandeira,
        card,
        cvc,
        mes,
        nome_cartao,
        parcela
      } = args;

    if(!bandeira){
        callAlert('Escolha a bandeira do cartão');
        return false;
    }

    if(!card){
        callAlert('Preencha o seu número do cartão!');
        return false;
    }

    if(!nome_cartao){
        callAlert('Preencha o nome do titular do cartão!');
        return false;
    }

    if(!mes){
        callAlert('Esccolha o mês de vencimento do cartão!');
        return false;
    }

    if(!ano){
        callAlert('Preencha o ano do cartão!');
        return false;
    }

    if(!cvc){
        callAlert('Preencha CVC do cartão!');
        return false;
    }

    if(!parcela){
        callAlert('Escolha a quantidade de parcelas do cartão!');
        return false;
    }
    
    makePayment(args);
}

function buyByBoleto(args) {
    makePayment(args);
}

function filterData(args) {
    const {
        nome,
        email,
        cpf_cnpj,
        senha,
        tipo,
        plano,
        gCaptcha,
        card,
        nome_cartao,
        ano,
        mes,
        cvc,
        bandeira,
        parcela
    } = args;

    const data = {};
    data.sexo = 'F';
    data.gCaptcha = gCaptcha;
    data.nome = nome;
    data.email = email;
    data.cpf_cnpj = cpf_cnpj;
    data.senha = senha;
    data.tipo_pagamento = tipo;
    
    data.id_plano = plano.id;
    data.valor = plano.valor;

    if(args.tipo === 'boleto'){
        //  do nothing
    } else {
        data.card_number = card;
        data.holder = nome_cartao;
        data.expiration_date = `${mes}/${ano}`;
        data.security_code = cvc;
        data.brand = bandeira;
        data.installments =  parcela.value;
    }

    return data;
}

function makePayment(args) {
    const data = filterData(args);
    
    Requisitions({
        url: WS.aderir,
        data,
        sucess:function(data){
          if(data.status){

            if(args.tipo === 'boleto'){
                const link = data.link;
                window.location.href = "https://foco90.com.br/pagamento/boleto/?link_boleto="+link;
            } else {
                window.location.href = "https://foco90.com.br/pagamento/cartao/";
            }
            
          } else {
            callErrorWhats();
          }
        },
        error:function(data){
          Dialog.create({
            title: 'Atenção',
            text: 'Ocorreu um erro ao retornar os planos. Por favor, recarregue a página novamente!'
          });
        }
      });
}

function callErrorWhats(){
    const msg = {
      title: 'Atenção',
      text: "Ocorreu um erro. Entre em contato com a gente pelo WhatsApp.",
      content: getContentError(),
      txtOk: 'Fechar'
    };
    Dialog2.create(msg);
  }

  function getContentError(){
    return <div style={{padding: 20}}>
      <a href="https://foco90.com.br/whatsapp/?type=atendimento" className="bt-assessoria-whats" target="_blank">
        <div className="link-menu">
          <i className="fab fa-whatsapp"></i>
          <span className="link-menu--txt">Atendimento</span>
          <div className="clear"></div>
        </div>
      </a>
    </div>;
  }

function callAlert(msg) {
    Dialog.create({
        title: 'Atenção',
        text: msg
    });
}
export { submitHandler };