import React, {useState, useEffect} from 'react';
import ReCAPTCHA from "react-google-recaptcha";


import Cartao from './Cartao';
import Boleto from './Boleto';

const CARTAO = 'cartao';
const BOLETO = 'boleto';
const GOOGLE_RECAPTCHA_V2 = '6Lew7acUAAAAAOwzL8TuxEmdm1JC9ynkJbqSSIwa';

export default function (props) {
    const [tipoPagamento, setTipoPagamento] = useState(CARTAO);

    useEffect( () => {
        props.onChangeValue({ value: CARTAO, label: 'tipo' });
      }, []);

    function changeTipoPagamento(type) {
        setTipoPagamento(type);
        props.onChangeValue({ value: type, label: 'tipo' });
    }

    function verifyCallback(recaptchaToken) {
        props.onChangeValue({ value: recaptchaToken, label: 'gCaptcha' });
    }

    return(
        <div>
            <h6 className="title inner">Forma de pagamento</h6>
            <div className="inner group box-radio">
            <div className='col-5'>
                <button className={`radio-box ${tipoPagamento === CARTAO ? 'ativo' : ''}`} onClick={() => changeTipoPagamento(CARTAO)}>
                    <span className='radio-button'><span></span></span>
                    <span>Cartão de crédito</span>
                </button>
            </div>
            <div className='col-5'>
                <button className={`radio-box ${tipoPagamento === BOLETO ? 'ativo' : ''}`} onClick={() => changeTipoPagamento(BOLETO)}>
                    <span className='radio-button'><span></span></span>
                    <span>Boleto</span>
                </button>
            </div>
            </div>
            <div className="col-7">
                {
                    tipoPagamento === CARTAO
                    ? <Cartao parcelas={props.parcelas} onChangeValue={props.onChangeValue} />
                    : <Boleto />
                }
            </div>
            <div className='inner' style={{marginTop:10}}>
                <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_V2}
                    onChange={verifyCallback}
                    hl='pt-BR'
                />
            </div>
        </div>
    );
}